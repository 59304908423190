<template>
  <div></div>
</template>

<script>


import axiosApiInstance from "@/utils/api";

export default {
name: "JoinAccountUexist",

  mounted() {

    if (this.$route.params.regtoken && this.$route.params.regtoken.length>10) {
      this.$store.commit('setRegToken', this.$route.params.regtoken )
    }

    axiosApiInstance.get(`/join-user-account/${this.$route.params.regtoken}`).then(resp =>{

      if (resp.status === 202 || resp.status === 200) {
        let team_id = resp.data.account.id
        this.$router.push({path: `/team/${team_id}`});
      }
    })



  }
}
</script>

<style scoped>

</style>
